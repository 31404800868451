import React from "react"
import { useAnalytics } from "../../../hooks/useAnalytics"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const withImageGrid =
  Component =>
  ({
    name = "ImageGrid",
    analytics = {},
    images,
    ratio,
    flexRatio,
    itemSpacing,
  }) => {
    const { windowSize } = useWindowSize()
    const { trackPromoImpression, trackPromoClick, VisibilitySensor } =
      useAnalytics()
    const isMobile = windowSize.width <= 768

    const ratios = []
    const rawFlexRatioWidths =
      isMobile && flexRatio?.widthMobile
        ? flexRatio?.widthMobile
        : flexRatio?.width
    const flexRatioWidths = rawFlexRatioWidths
      ?.split(",")
      .map(val => parseInt(val))
    const flexRatioHeight = parseInt(flexRatio?.height)

    for (let i = 0; i < images?.length; i++) {
      const flexRatioWidth =
        flexRatioWidths?.[Math.min(i, flexRatioWidths.length - 1)]
      const width = flexRatioWidth
        ? flexRatioWidth
        : ratio?.width
        ? ratio.width
        : 1
      const height = flexRatioHeight ? flexRatioHeight : ratio?.height
      ratios.push({ width, height })
    }

    Component.displayName = name
    return (
      <VisibilitySensor
        onChange={visible => visible && trackPromoImpression({ ...analytics })}
      >
        <Component
          images={images}
          itemSpacing={itemSpacing}
          ratios={ratios}
          handlePromoClick={() => trackPromoClick({ ...analytics })}
        />
      </VisibilitySensor>
    )
  }
